export const global = {
  SET_ROUTE: "SET_ROUTE",
  SHOW_SNACKBAR: "SHOW_SNACKBAR",
};

export const TEST = {
  SET_TEST: "SET_TEST",
};

export const SET_PROGRAM_LIST = 'SET_PROGRAM_LIST';
export const SET_PROGRAM_ID = 'SET_PROGRAM_ID';


export const Auth = {
  LOGIN_LOADING: "LOGIN_LOADING",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LIST_USER_SUCCESS: "LIST_USER_SUCCESS",
  LIST_USER_FAILURE: "LIST_USER_FAILURE",
  DETAIL_USER_SUCCESS: "DETAIL_USER_SUCCESS",
  DETAIL_USER_FAILURE: "DETAIL_USER_FAILURE",
  Sign_UP: "Sign_UP",
  SIGNUP_LOADING: " SIGNUP_LOADING",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  SIGNUP_FAILURE: "SIGNUP_FAILURE",
  VALIDATE_EMAIL_LOADING: " VALIDATE_EMAIL_LOADING",
  VALIDATE_EMAIL_SUCCESS: "VALIDATE_EMAIL_SUCCESS",
  VALIDATE_EMAIL_FAILURE: "VALIDATE_EMAIL_FAILURE",
  DISCIPLINE_LIST_LOADING: "DISCIPLINE_LIST_LOADING",
  DISCIPLINE_LIST_SUCCESS: "DISCIPLINE_LIST_SUCCESS",
  DISCIPLINE_LIST_FAILURE: "DISCIPLINE_LIST_FAILURE",
  DEGREE_LIST_LOADING: "DEGREE_LIST_LOADING",
  DEGREE_LIST_SUCCESS: "DEGREE_LIST_SUCCESS",
  DEGREE_LIST_FAILURE: "DEGREE_LIST_FAILURE",
  PROFILE_UPDATE: "PROFILE_UPDATE",
  EMAIL_RESET_LOADING: "EMAIL_RESET_LOADING",
  EMAIL_RESET_SUCCESS: "EMAIL_RESET_SUCCESS",
  EMAIL_RESET_FAILURE: "EMAIL_RESET_FAILURE",
  VALIDATE_PASSWORD: "VALIDATE_PASSWORD",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",
  RESET__PASSWORD_LOADING: "RESET__PASSWORD_LOADING",
  RESET__PASSWORD_SUCCESS: "RESET__PASSWORD_SUCCESS",
  RESET__PASSWORD_FAILURE: "RESET__PASSWORD_FAILURE",
};


export const Chart = {
  CREATE_CHART_SUCCESS: "CREATE_CHART_SUCCESS",
  CREATE_CHART_FAILURE: "CREATE_CHART_FAILURE",
  CHART_CREATION_DETAILS: "CHART_CREATION_DETAILS",
  CHART_LIST: "CHART_LIST",
  CHART_UPDATE_STATUS: "CHART_UPDATE_STATUS",
  CREATE_CONTEXT: "CREATE_CONTEXT",
  CONTEXT_LIST: "CONTEXT_LIST",
  CONTEXT_LIST_DELETE: "CONTEXT_LIST_DELETE",
};

export const Member = {
  MEMBER_LIST: "MEMBER_LIST",
  ADD_MEMBER: "ADD_MEMBER",
  UPLOAD_IMAGE: "UPLOAD_IMAGE",
  UPLOAD_IMAGE_FAILED: "UPLOAD_IMAGE_FAILED",
  USER_TYPES: "USER_TYPES",
  RESEND_MAIL: "RESEND_MAIL",
  ARCHIVE_USER: "ARCHIVE_USER",
  DELETE_USER: "DELETE_USER",
  USER_LIST_REQUEST: "USER_LIST_REQUEST",
  USER_LIST_SUCCESS: "USER_LIST_SUCCESS",
  USER_LIST_FAILURE: "USER_LIST_FAILURE",
  PROGRAM_LIST_REQUEST: "PROGRAM_LIST_REQUEST",
  PROGRAM_LIST_SUCCESS: "PROGRAM_LIST_SUCCESS",
  PROGRAM_LIST_FAILURE: "PROGRAM_LIST_FAILURE",
  INVITATION_REQUEST: "INVITATION_REQUEST",
  INVITATION_SUCCESS: "INVITATION_SUCCESS",
  INVITATION_FAILURE: "INVITATION_FAILURE",
  INVITE_USER_REQUEST: "INVITE_USER_REQUEST",
  INVITE_USER_SUCCESS: "INVITE_USER_SUCCESS",
  INVITE_USER_FAILURE: "INVITE_USER_FAILURE",
  HEADER_PROGRAM_LIST_REQUEST: "HEADER_PROGRAM_LIST_REQUEST",
  HEADER_PROGRAM_LIST_SUCCESS: "HEADER_PROGRAM_LIST_SUCCESS",
  HEADER_PROGRAM_LIST_FAILURE: "HEADER_PROGRAM_LIST_FAILURE",
  VALIDATE_USER_REQUEST: "VALIDATE_USER_REQUEST",
  VALIDATE_USER_SUCCESS: "VALIDATE_USER_SUCCESS",
  VALIDATE_USER_FAILURE: "VALIDATE_USER_FAILURE",
  INSTITUTION_LIST_REQUEST: "INSTITUTION_LIST_REQUEST",
  INSTITUTION_LIST_SUCCESS: "INSTITUTION_LIST_SUCCESS",
  INSTITUTION_LIST_FAILURE: "INSTITUTION_LIST_FAILURE",
  // GET_GRADUATION_DATE_REQUEST: "GET_GRADUATION_DATE_REQUEST",
  // GET_GRADUATION_DATE_SUCCESS: "GET_GRADUATION_DATE_SUCCESS",
  // GET_GRADUATION_DATE_FAILURE: "GET_GRADUATION_DATE_FAILURE",
};

export const MyProgram = {
  PROGRAM_LIST_REQUEST: "PROGRAM_LIST_REQUEST",
  PROGRAM_LIST_SUCCESS: "PROGRAM_LIST_SUCCESS",
  PROGRAM_LIST_FAILURE: "PROGRAM_LIST_FAILURE",
  DEPARTMENT_LIST_REQUEST: "DEPARTMENT_LIST_REQUEST",
  DEPARTMENT_LIST_SUCCESS: "DEPARTMENT_LIST_SUCCESS",
  DEPARTMENT_LIST_FAILURE: "DEPARTMENT_LIST_FAILURE",
  MEMBER_LIST: "MEMBER_LIST",
  USER_TYPES: "USER_TYPES",
  INVITE_CREATE_REQUEST: "INVITE_CREATE_REQUEST",
  INVITE_CREATE_SUCCESS: "INVITE_CREATE_SUCCESS",
  INVITE_CREATE_FAILURE: "INVITE_CREATE_FAILURE",
  FILTER_LIST_REQUEST: "FILTER_LIST_REQUEST",
  FILTER_LIST_SUCCESS: "FILTER_LIST_SUCCESS",
  FILTER_LIST_FAILURE: "FILTER_LIST_FAILURE",
  SEND_INVITE_REQUEST: "SEND_INVITE_REQUEST",
  SEND_INVITE_SUCCESS: "SEND_INVITE_SUCCESS",
  SEND_INVITE_FAILURE: "SEND_INVITE_FAILURE",
  PROGRAM_DETAILS: "PROGRAM_DETAILS",
  // GET_GRADUATION_DATE_REQUEST: "GET_GRADUATION_DATE_REQUEST",
  // GET_GRADUATION_DATE_SUCCESS: "GET_GRADUATION_DATE_SUCCESS",
  // GET_GRADUATION_DATE_FAILURE: "GET_GRADUATION_DATE_FAILURE",
  PROGRAM_FULL_DETAILS: "PROGRAM_FULL_DETAILS",
  LOGOUT: "LOGOUT",
  USER_ROLE_SET_REQUEST: "USER_ROLE_SET_REQUEST",
  USER_ROLE_SET_SUCCESS: "USER_ROLE_SET_SUCCESS",
};

export const Invitation = {
  INSTITUTION_BASED_PROGRAM_LIST_REQUEST: "INSTITUTION_BASED_PROGRAM_LIST_REQUEST",
  INSTITUTION_BASED_PROGRAM_LIST_SUCCESS: "INSTITUTION_BASED_PROGRAM_LIST_SUCCESS",
  INSTITUTION_BASED_PROGRAM_LIST_FAILURE: "INSTITUTION_BASED_PROGRAM_LIST_FAILURE",
}

export const Directory = {
  PROGRAM_LIST_REQUEST: "PROGRAM_LIST_REQUEST",
  PROGRAM_LIST_SUCCESS: "PROGRAM_LIST_SUCCESS",
  PROGRAM_LIST_FAILURE: "PROGRAM_LIST_FAILURE",
  MEMBER_LIST: "MEMBER_LIST",
  USER_TYPES: "USER_TYPES",
  FILTER_LIST_REQUEST: "FILTER_LIST_REQUEST",
  FILTER_LIST_SUCCESS: "FILTER_LIST_SUCCESS",
  FILTER_LIST_FAILURE: "FILTER_LIST_FAILURE",

}
export const Home = {
  PROGRAM_LIST_REQUEST: "PROGRAM_LIST_REQUEST",
  PROGRAM_LIST_SUCCESS: "PROGRAM_LIST_SUCCESS",
  PROGRAM_LIST_FAILURE: "PROGRAM_LIST_FAILURE",
  GET_GRADUATION_DATE_REQUEST: "GET_GRADUATION_DATE_REQUEST",
  GET_GRADUATION_DATE_SUCCESS: "GET_GRADUATION_DATE_SUCCESS",
  GET_GRADUATION_DATE_FAILURE: "GET_GRADUATION_DATE_FAILURE",
  UME_PROGRAM_FULL_DETAILS: "UME_PROGRAM_FULL_DETAILS"

}

export const Password = {
  SET_PASSWORD: "SET_PASSWORD",
  FORGOTPASSWORD: "FORGOTPASSWORD",
  VALID_LINK: "VALID_LINK",
  EMAIL_RESET_PASSWORD: "EMAIL_RESET_PASSWORD",
  EMAIL_FORGOT_PASSWORD: "EMAIL_FORGOT_PASSWORD",
};

export const growthChart = {
  GROWTH_CHART_DETAILS_LOADING: "GROWTH_CHART_DETAILS_LOADING",
  GROWTH_CHART_DETAILS_SUCCESS: "GROWTH_CHART_DETAILS_SUCCESS",
  GROWTH_CHART_DETAILS_FAILURE: "GROWTH_CHART_DETAILS_FAILURE",
  GROWTH_CHART_USER_LIST_LOADING: "GROWTH_CHART_USER_LIST_LOADING",
  GROWTH_CHART_USER_LIST_SUCCESS: "GROWTH_CHART_USER_LIST_SUCCESS",
  GROWTH_CHART_USER_LIST_FAILURE: "GROWTH_CHART_USER_LIST_FAILURE",
  SET_GROWTH_CHART_REDUX: "SET_GROWTH_CHART_REDUX",
  GROWTH_CHART_CONTEXT_LOADING: "GROWTH_CHART_CONTEXT_LOADING",
  GROWTH_CHART_CONTEXT_SUCCESS: "GROWTH_CHART_CONTEXT_SUCCESS",
  GROWTH_CHART_CONTEXT_FAILURE: "GROWTH_CHART_CONTEXT_FAILURE",
  GROWTH_CHART_EVALUATION_DETAILS_LOADING:
    "GROWTH_CHART_EVALUATION_DETAILS_LOADING",
  GROWTH_CHART_EVALUATION_DETAILS_SUCCESS:
    "GROWTH_CHART_EVALUATION_DETAILS_SUCCESS",
  GROWTH_CHART_EVALUATION_DETAILS_FAILURE:
    "GROWTH_CHART_EVALUATION_DETAILS_FAILURE",
  EVALUATED_USER_LIST_LOADING: "EVALUATED_USER_LIST_LOADING",
  EVALUATED_USER_LIST_SUCCESS: "EVALUATED_USER_LIST_SUCCESS",
  EVALUATED_USER_LIST_FAILURE: "EVALUATED_USER_LIST_FAILURE",
  EVALUATION_NARRATIVES_DETAILS_LOADING:
    "EVALUATION_NARRATIVES_DETAILS_LOADING",
  EVALUATION_NARRATIVES_DETAILS_SUCCESS:
    "EVALUATION_NARRATIVES_DETAILS_SUCCESS",
  EVALUATION_NARRATIVES_DETAILS_FAILURE:
    "EVALUATION_NARRATIVES_DETAILS_FAILURE",
  ON_SAVE_EVALUATION_LOADING: "ON_SAVE_EVALUATION_LOADING",
  ON_SAVE_EVALUATION_SUCCESS: "ON_SAVE_EVALUATION_SUCCESS",
  ON_SAVE_EVALUATION_FAILURE: "ON_SAVE_EVALUATION_FAILURE",
  GET_CCC_VALUATIONS_LOADING: "GET_CCC_VALUATIONS_LOADING",
  GET_CCC_VALUATIONS_SUCCESS: "GET_CCC_VALUATIONS_SUCCESS",
  GET_CCC_VALUATIONS_FAILURE: "GET_CCC_VALUATIONS_FAILURE",
  PUBLISH_CCC_LOADING: "PUBLISH_CCC_LOADING",
  PUBLISH_CCC_SUCCESS: "PUBLISH_CCC_SUCCESS",
  PUBLISH_CCC_FAILURE: "PUBLISH_CCC_FAILURE",
  GET_LIST_CHART_DETAILS_LOADING: "GET_LIST_CHART_DETAILS_LOADING",
  GET_LIST_CHART_DETAILS_SUCCESS: "GET_LIST_CHART_DETAILS_SUCCESS",
  GET_LIST_CHART_DETAILS_FAILURE: "GET_LIST_CHART_DETAILS_FAILURE",
  CCC_EVALUATION_DETAILS_LOADING: "CCC_EVALUATION_DETAILS_LOADING",
  CCC_EVALUATION_DETAILS_SUCCESS: "CCC_EVALUATION_DETAILS_SUCCESS",
  CCC_EVALUATION_DETAILS_FAILURE: "CCC_EVALUATION_DETAILS_FAILURE",
  DELETE_CCC_SUCCESS: "DELETE_CCC_SUCCESS",
  DELETE_CCC_FAILURE: "DELETE_CCC_FAILURE"
};

export const CCCEvaluations = {
  EVALUATIONS_LIST: "EVALUATIONS_LIST",
  UNEVALUATED_LIST_LOADING: "UNEVALUATED_LIST_LOADING",
  UNEVALUATED_LIST_SUCCESS: "UNEVALUATED_LIST_SUCCESS",
  UNEVALUATED_LIST_FAILURE: "UNEVALUATED_LIST_FAILURE",
  SAVE_FEEDBACK_LOADING: "SAVE_FEEDBACK_LOADING",
  SAVE_FEEDBACK_SUCCESS: "SAVE_FEEDBACK_SUCCESS",
  SAVE_FEEDBACK_FAILURE: "SAVE_FEEDBACK_FAILURE",
  EVALUATED_LIST_LOADING: "EVALUATED_LIST_LOADING",
  EVALUATED_LIST_SUCCESS: "EVALUATED_LIST_SUCCESS",
  EVALUATED_LIST_FAILURE: "EVALUATED_LIST_FAILURE",
  START_CCC_LIST: "START_CCC_LIST",
  PUBLISHED_LIST_REQUEST: "PUBLISHED_LIST_REQUEST",
  PUBLISHED_LIST_SUCCESS: "PUBLISHED_LIST_SUCCESS",
  PUBLISHED_LIST_FAILURE: "PUBLISHED_LIST_FAILURE"
};
// Feedback List
export const feedBackList = {
  FEEDBACK_LIST_LOADING: "FEEDBACK_LIST_LOADING",
  FEEDBACK_LIST_SUCCESS: "FEEDBACK_LIST_SUCCESS",
  FEEDBACK_LIST_FAILURE: "FEEDBACK_LIST_FAILURE",
};
//handoff List
export const handoffList = {
  HANDOFF_LIST_LOADING: "HANDOFF_LIST_LOADING",
  HANDOFF_LIST_SUCCESS: "HANDOFF_LIST_SUCCESS",
  HANDOFF_LIST_FAILURE: "HANDOFF_LIST_FAILURE",
};