import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import MenuIcon from "@material-ui/icons/Menu";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "./header.css";
import Avatar from "@material-ui/core/Avatar";
import { useHistory } from "react-router-dom";
import "react-select2-wrapper/css/select2.css";
import RGCLogo from "../../../Assets/Images/DashLogo.png";
import RGCMobLogo from "../../../Assets/Images/RGC_Logo22.png";
import { connect, useSelector } from "react-redux";
import { setRoute } from "../../../store/actions/Global";
import SimpleListMenu from "../Menu";
import { UserDetails, getUser, logout } from "../../../store/actions/Auth";
import DialogNotification from "../Modal";
import MyProfile from "./myProfile";
import OwnDropDown from "../ReactSelect";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Box } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  appBar: {
    width: "100%",
    zIndex: 10,
  },
  listRoot: {
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  logo: {
    /*  marginLeft: "2%", */
    cursor: "pointer",
  },
  regular: {
    minHeight: 80,
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  // color: theme.palette.text.secondary,
  boxShadow: "none",
  padding: 0,
  width: 450,
}));

const Header = (props) => {
  const { setOpen } = props;
  const [hidingStyle, setHidingStyle] = useState(false);
  const [editing, setEditing] = useState(false);
  const [anchorEl, setAnchorEl] = useState(false);
  const [sideDrawerOpen, setSideDrawerOpen] = React.useState(false);
  const [myProfileModalOpen, setMyProfileModalOpen] = useState(false);
  const [logoutModalOpen, setLogoutModalOpen] = React.useState(false);

  const history = useHistory();

  const state = useSelector((state) => state);
  const loginDetails = state?.AuthReducer?.detailsList?.userDetails;
  const { programDetailsUME } = useSelector((state) => state?.HomeReducer)

  const classes = useStyles();
  const matches = useMediaQuery("(max-width:1181px)");
  const smallDevices = useMediaQuery("(max-width:1200px)");

  useEffect(() => {
    if (!matches && sideDrawerOpen) {
      setSideDrawerOpen(false);
    }
  }, [matches, sideDrawerOpen]);

  const componentDidMount = async () => {
    let userId = localStorage.getItem("userId");
    await props.UserDetails({ ID: userId });
  };

  useEffect(() => {
    componentDidMount();
  }, []);

  const handleMenuItemClick = (options) => {
    if (options === "logout") {
      setLogoutModalOpen(true);
    }
  };

  const onRenderComponent = () => {
    return (
      <Grid style={{ minHeight: 80, padding: 20, display: "flex", alignItems: "center" }}>
        <Typography variant="body2" color="textSecondary">
          Are you sure you want to logout?
        </Typography>
      </Grid>
    );
  };

  const handleDialogWindowClose = () => {
    setLogoutModalOpen(false);
  };

  const onDialogActionsOnClick = async () => {
    await props.logout();
    localStorage.clear();
    history.push("/");
    window.location.reload(); // Reload the page
  };

  const handleCloseMyProfile = () => {
    setMyProfileModalOpen(false);
    setEditing(false);
  };


  return (
    <>
      <CssBaseline />
      <AppBar color="inherit" position="fixed" className={classes.appBar} elevation={0}>
        <Toolbar className={classes.regular}>
          <Grid container justifyContent="space-between" alignItems="center" spacing={0}>
            {/* Project logo */}
            <Grid item xs={5} lg={3}>
              <img
                className={classes.logo}
                src={smallDevices ? RGCMobLogo : RGCLogo}
                width={smallDevices ? 60 : 280}
                height={smallDevices ? 60 : 55}
              />
              {/* <img className={classes.logo} src={RGCLogo} /> */}
            </Grid>
            {/* Sponser Name & Program Name */}
            <Grid item xs={7} lg={4} style={{ display: "flex", justifyContent: smallDevices ? "flex-end" : "center" }}>
              <Item>
                <Typography variant="body2" style={{ fontWeight: 'bold' }}>{programDetailsUME?.Instituition?.Name}</Typography>
                <Typography variant="body2">
                  {programDetailsUME?.label || programDetailsUME?.Name}
                </Typography>
              </Item>
            </Grid>
            {matches &&
              <Grid item xs={6} >
                {matches ? (
                  <IconButton style={{ margin: "0px 5px" }} onClick={() => setOpen(true)}>
                    <MenuIcon />
                  </IconButton>
                ) : null}
              </Grid>
            }
            {/* Searchable dropdown and my-profile */}
            <Grid item xs={6} lg={4} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
              {/* Searchable program dropdown */}
              <OwnDropDown hidingStyle={hidingStyle} />

              {/* Right side notification button and my profile for logout */}
              <Grid>
                <IconButton onClick={() => setMyProfileModalOpen(!myProfileModalOpen)}>
                  <Avatar
                    title={loginDetails?.FirstName + " " + loginDetails?.LastName}
                    src={loginDetails?.Picture}
                  />
                </IconButton>

                {anchorEl ? (
                  <SimpleListMenu
                    anchorEl={anchorEl}
                    options={[{ id: "logout", title: "Logout" }]}
                    handleMenuItemClick={(options) => handleMenuItemClick(options)}
                    onClose={() => setAnchorEl(null)}
                  />
                ) : null}

                {myProfileModalOpen ? (
                  <MyProfile
                    myProfileModalOpen={myProfileModalOpen}
                    handleCloseMyProfile={handleCloseMyProfile}
                    handleMenuItemClick={handleMenuItemClick}
                    setAnchorEl={setAnchorEl}
                    listUser={props.listUser}
                    setEditing={setEditing}
                    editing={editing}
                  />
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      {logoutModalOpen ? (
        <DialogNotification
          // hideDividers
          dialogActions={[
            { text: "No", onClick: () => handleDialogWindowClose() },
            { text: "Yes", onClick: () => onDialogActionsOnClick(), proceedAction: true },
          ]}
          subRendercomponent={onRenderComponent()}
          title="Logout?"
          dialogRootProps={{ open: logoutModalOpen, onClose: () => setLogoutModalOpen(false) }}
        />
      ) : null}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    route: state.global.route,
    listUser: state.AuthReducer.detailsList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setRoute: (data) => dispatch(setRoute(data)),
    logout: () => dispatch(logout()),
    getUser: (data) => dispatch(getUser(data)),
    UserDetails: (data) => dispatch(UserDetails(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
