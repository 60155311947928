import { feedBackList, growthChart, handoffList } from "../../actionTypes";

const initialState = {
  growthChartDetails: {},
  isLoading: false,
  userList: {},
  isCheckedResident: true,
  isCheckedFaculty: true,
  isCheckedSelf: true,
  isCheckedCCC: true,
  isCheckedAll: true,
  context: {},
  evaluationDetails: {},
  evaluatedUserList: {},
  narrativesDetails: {},
  onSaveEvaluation: {},
  cccValuations: {},
  publishCCC: {},
  listChartDetails: {},
  feedBackList: {},
  handOffList: {},
  cccEvaluationDetails: {}
};

const growthChartReducer = (state = initialState, action) => {
  switch (action.type) {
    case growthChart.GROWTH_CHART_DETAILS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case growthChart.GROWTH_CHART_DETAILS_SUCCESS:
      let facultyEvaluation = [];
      let residentEvaluation = [];
      let selfEvaluation = [];
      let cccEvaluation = [];
      const StartDate = new Date(action.data?.StartDate);
      const EndDate = new Date(action.data?.EndDate);
      const XAxisValues = action.data?.XAxisValues
        ? action.data?.XAxisValues?.map((x) => new Date(x))
        : null;
      if (
        action.data?.facultyEvaluation &&
        action.data?.facultyEvaluation.length
      ) {
        facultyEvaluation = action.data?.facultyEvaluation.map((row) => {
          let x = new Date(row["x"]);
          row["x"] = x;
          row["label"] = row["label"] > 1 ? row["label"] : "";
          return row;
        });
      }
      if (
        action.data?.residentEvaluation &&
        action.data?.residentEvaluation.length
      ) {
        residentEvaluation = action.data?.residentEvaluation.map((row) => {
          let x = new Date(row["x"]);
          row["x"] = x;
          row["label"] = row["label"] > 1 ? row["label"] : "";
          return row;
        });
      }
      if (action.data?.selfEvaluation && action.data?.selfEvaluation.length) {
        selfEvaluation = action.data?.selfEvaluation.map((row) => {
          let x = new Date(row["x"]);
          row["x"] = x;
          row["label"] = row["label"] > 1 ? row["label"] : "";
          return row;
        });
      }
      if (action.data?.cccEvaluation && action.data?.cccEvaluation.length) {
        cccEvaluation = action.data?.cccEvaluation.map((row) => {
          let x = new Date(row["x"]);
          row["x"] = x;
          row["label"] = row["label"] > 1 ? row["label"] : "";
          return row;
        });
      }
      Promise.all(XAxisValues);
      Promise.all(facultyEvaluation);
      Promise.all(residentEvaluation);
      Promise.all(selfEvaluation);
      Promise.all(cccEvaluation);
      const newActionData = {
        ...action.data,
        XAxisValues,
        facultyEvaluation,
        residentEvaluation,
        selfEvaluation,
        cccEvaluation,
        StartDate,
        EndDate,
      };

      return {
        ...state,
        growthChartDetails: { success: true, ...newActionData },
        isLoading: action.isLoading,
      };
    case growthChart.GROWTH_CHART_DETAILS_FAILURE:
      return {
        ...state,
        growthChartDetails: { success: false, ...action.error },
      };
    case growthChart.GROWTH_CHART_USER_LIST_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case growthChart.GROWTH_CHART_USER_LIST_SUCCESS:
      return {
        ...state,
        userList: { success: true, evalueeData: action.data },
      };
    case growthChart.GROWTH_CHART_USER_LIST_FAILURE:
      return {
        ...state,
        userList: { success: false, ...action.error },
      };
    case growthChart.SET_GROWTH_CHART_REDUX:
      state[action.key] = action.value;
      return {
        ...state,
      };
    case growthChart.GROWTH_CHART_CONTEXT_SUCCESS:
      return {
        ...state,
        context: { success: true, context: action.data },
      };
    case growthChart.GROWTH_CHART_CONTEXT_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case growthChart.GROWTH_CHART_CONTEXT_FAILURE:
      return {
        ...state,
        context: { success: false, ...action.error },
      };
    case growthChart.GROWTH_CHART_EVALUATION_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        evaluationDetails: { success: true, data: action.data },
      };
    case growthChart.GROWTH_CHART_EVALUATION_DETAILS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case growthChart.GROWTH_CHART_EVALUATION_DETAILS_FAILURE:
      return {
        ...state,
        evaluationDetails: { success: false, ...action.error },
      };

    case growthChart.EVALUATED_USER_LIST_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case growthChart.EVALUATED_USER_LIST_SUCCESS:
      return {
        ...state,
        evaluatedUserList: { success: true, evaluatedUserList: action.data },
      };
    case growthChart.EVALUATED_USER_LIST_FAILURE:
      return {
        ...state,
        evaluatedUserList: { success: false, ...action.error },
      };
    case growthChart.EVALUATION_NARRATIVES_DETAILS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case growthChart.EVALUATION_NARRATIVES_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        narrativesDetails: { success: true, narrativesDetails: action.data },
      };
    case growthChart.EVALUATION_NARRATIVES_DETAILS_FAILURE:
      return {
        ...state,
        narrativesDetails: { success: false, ...action.error },
      };
    case growthChart.ON_SAVE_EVALUATION_LOADING:
      return {
        ...state,
        isLoadingCCC: true,
      };
    case growthChart.ON_SAVE_EVALUATION_SUCCESS:
      return {
        ...state,
        isLoadingCCC: false,
        onSaveEvaluation: { success: true, data: action.data },
      };
    case growthChart.ON_SAVE_EVALUATION_FAILURE:
      return {
        ...state,
        isLoadingCCC: false,
        onSaveEvaluation: { success: false, ...action.error },
      };

    case growthChart.GET_CCC_VALUATIONS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case growthChart.GET_CCC_VALUATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cccValuations: { success: true, data: action.data },
      };
    case growthChart.GET_CCC_VALUATIONS_FAILURE:
      return {
        ...state,
        cccValuations: { success: false, ...action.error },
      };
    case growthChart.PUBLISH_CCC_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case growthChart.PUBLISH_CCC_SUCCESS:
      return {
        ...state,
        isLoading: false,
        publishCCC: { success: true, data: action.data },
      };
    case growthChart.PUBLISH_CCC_FAILURE:
      return {
        ...state,
        publishCCC: { success: false, ...action.error },
      };
    case growthChart.GET_LIST_CHART_DETAILS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case growthChart.GET_LIST_CHART_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listChartDetails: { success: true, data: action.data },
      };
    case growthChart.GET_LIST_CHART_DETAILS_FAILURE:
      return {
        ...state,
        listChartDetails: { success: false, ...action.error },
      };
    // Feedback List
    case feedBackList.FEEDBACK_LIST_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case feedBackList.FEEDBACK_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        feedBackList: { success: true, data: action.data },
      };
    case feedBackList.FEEDBACK_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        feedBackList: { success: false, ...action.error },
      };
    case handoffList.HANDOFF_LIST_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case handoffList.HANDOFF_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        feedBackList: { success: true, data: action.data },
      };
    case handoffList.HANDOFF_LIST_FAILURE:
      return {
        ...state,
        feedBackList: { success: false, ...action.error },
      };
    case growthChart.CCC_EVALUATION_DETAILS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case growthChart.CCC_EVALUATION_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cccEvaluationDetails: { success: true, data: action.data },
      };
    case growthChart.CCC_EVALUATION_DETAILS_FAILURE:
      return {
        ...state,
        cccEvaluationDetails: { success: false, ...action.error },
      };
    default:
      return state;
  }
};
export default growthChartReducer;
