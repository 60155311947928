import { Member } from "../../actionTypes";

const initialState = {
  membersList: {},
  failedMessage: null,
  institutionList: {},
  isMemberLoading: false,
  userTypeLoading: false,
};

const MemberReducer = (state = initialState, action) => {
  switch (action.type) {
    case Member.MEMBER_LIST:
      const isData = action.data ? { membersList: { ...action.data } } : null;
      return {
        ...state,
        ...isData,
        isLoading: action.isLoading,
      };
    case Member.ADD_MEMBER:
      const addMember = action.data ? { addMember: { ...action.data } } : null;
      return {
        ...state,
        ...addMember,
        isLoading: action.isLoading,
      };
    case Member.UPLOAD_IMAGE:
      const imageDetails = action.data ? { imageDetails: { ...action.data } } : null;
      return {
        ...state,
        ...imageDetails,
        isLoading: action.isLoading,
      };
    case Member.UPLOAD_IMAGE_FAILED:
      const failedMessage = action.data ? { failedMessage: { ...action.message } } : null;
      return {
        ...state,
        ...failedMessage,
        isLoading: action.isLoading,
      };
    case Member.USER_TYPES:
      const userTypes = action.data ? { userTypes: { ...action.data } } : null;
      return {
        ...state,
        ...userTypes,
        userTypeLoading: action.isLoading,
      };
    case Member.ARCHIVE_USER:
      let updateStatus = null;
      if (action?.typeOfMember === 0) {
        const updatedData = action.data
          ? state.membersList?.data?.rows?.map((x) =>
              x.ID === action.data?.ID ? { ...x, Archive: action.data?.Archive } : x
            )
          : null;
        updateStatus = updatedData
          ? {
              membersList: {
                ...state.membersList,
                data: { rows: updatedData, count: state.membersList?.data?.count },
              },
            }
          : null;
      } else {
        const updatedData = action.data
          ? state.membersList?.data?.rows?.filter((x) => x.ID !== action.data?.ID)
          : null;
        updateStatus = updatedData
          ? {
              membersList: {
                ...state.membersList,
                data: { rows: updatedData, count: state.membersList?.data?.count },
              },
            }
          : null;
      }
      // const archieveUser = action.data ? { archieveUser: { ...action.data } } : null;
      return {
        ...state,
        ...updateStatus,
        isLoading: action.isLoading,
      };
    case Member.DELETE_USER:
      // const deletedUser = action.data ? { deletedUser: { ...action.data } } : null;
      const deleteData = action.data
        ? state.membersList?.data?.rows?.filter((item) => item.ID !== action.data?.data)
        : null;
      const memberDeleteList = deleteData
        ? {
            membersList: {
              ...state.membersList,
              data: { rows: deleteData, count: state.membersList?.data?.count - 1 },
            },
          }
        : null;
      return {
        ...state,
        ...memberDeleteList,
        isLoading: action.isLoading,
      };
    case Member.USER_LIST_REQUEST:
      return { ...state, isLoading: true };
    case Member.USER_LIST_SUCCESS:
      return { ...state, isLoading: false, userList: action.payload };
    case Member.USER_LIST_FAILURE:
      return { ...state, isLoading: false, error: action.payload };
    case Member.PROGRAM_LIST_REQUEST:
      return { ...state, isMemberLoading: true };
    case Member.PROGRAM_LIST_SUCCESS:
      return {
        ...state,
        isMemberLoading: false,
        programList: action.payload,
        membersList: { ...state.membersList },
      };
    case Member.PROGRAM_LIST_FAILURE:
      return { ...state, isMemberLoading: false, error: action.payload };
    case Member.INVITATION_REQUEST:
      return { ...state, isLoading: true };
    case Member.INVITATION_SUCCESS:
      return { ...state, isLoading: false, invitation: action.payload };
    case Member.INVITATION_FAILURE:
      return { ...state, isLoading: false, error: action.payload };
    case Member.INVITE_USER_REQUEST:
      return { ...state, isLoading: true };
    case Member.INVITE_USER_SUCCESS:
      return { ...state, isLoading: false, invitedUser: action.payload, imageDetails: "" };
    case Member.INVITE_USER_FAILURE:
      return { ...state, isLoading: false, error: action.payload };
    case Member.HEADER_PROGRAM_LIST_REQUEST:
      return { ...state, isLoading: true };
    case Member.HEADER_PROGRAM_LIST_SUCCESS:
      return { ...state, isLoading: false, headerPrograms: action.payload };
    case Member.HEADER_PROGRAM_LIST_FAILURE:
      return { ...state, isLoading: false, error: action.payload };
    case Member.VALIDATE_USER_REQUEST:
      return { ...state, isLoading: false };
    case Member.VALIDATE_USER_SUCCESS:
      return { ...state, isLoading: false, userExist: action.payload };
    case Member.VALIDATE_USER_FAILURE:
      return { ...state, isLoading: false, error: action.payload };

    case Member.INSTITUTION_LIST_REQUEST:
      return { ...state, isLoading: false };
    case Member.INSTITUTION_LIST_SUCCESS:
      return { ...state, isLoading: false, institutionList: action.payload };
    case Member.INSTITUTION_LIST_FAILURE:
      return { ...state, isLoading: false, error: action.payload };

    // case Member.GET_GRADUATION_DATE_REQUEST:
    //     return { isLoading: true };
    // case Member.GET_GRADUATION_DATE_SUCCESS:
    //     return { ...state, isLoading: false, graduationDate: action.payload };
    // case Member.GET_GRADUATION_DATE_FAILURE:
    //     return { isLoading: false, error: action.payload };
    default:
      return state;
  }
};
export default MemberReducer;
