import { Home } from "../../actionTypes";

const initialState = {
  directoryList: {},
  programDetailsUME: {},
  graduationDate: ""
};

const HomeReducer = (state = initialState, action) => {
  switch (action.type) {
    case Home.PROGRAM_LIST_REQUEST:
      return { ...state, isLoading: true };
    case Home.MEMBER_LIST:
      return { ...state, isLoading: true };
    case Home.USER_TYPES:
      return { ...state, isLoading: true };
    case Home.PROGRAM_LIST_SUCCESS:
      return { ...state, isLoading: false, data: action.payload };
    case Home.PROGRAM_LIST_FAILURE:
      return { ...state, isLoading: false, error: action.payload };

    case Home.GET_GRADUATION_DATE_REQUEST:
      return { ...state, isLoading: true };
    case Home.GET_GRADUATION_DATE_SUCCESS:
      return { ...state, isLoading: false, graduationDate: action.payload };
    case Home.GET_GRADUATION_DATE_FAILURE:
      return { ...state, isLoading: false, error: action.payload };
    case Home.UME_PROGRAM_FULL_DETAILS:
      return { ...state, programDetailsUME: action.payload, };

    default:
      return state;
  }
};
export default HomeReducer;
