import axios from "axios";
import { userEndpoints } from "../../../Config/serviceEndPoints";
import { Home } from "../../actionTypes";
import gateWay from "../../../Config/authorizationService";

export const homeMemberList = () => async (dispatch) => {
  dispatch({ type: Home.PROGRAM_LIST_REQUEST });
  try {
    const { data } = axios.get("pages/Home/index");

    if (data) {
      dispatch({ type: Home.PROGRAM_LIST_SUCCESS, payload: data });
    }
  } catch (error) {
    dispatch({
      type: Home.PROGRAM_LIST_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getEstimatedGraduationDate = (params) => async (dispatch) => {
  dispatch({ type: Home.GET_GRADUATION_DATE_REQUEST });
  try {
    const { data } = await gateWay.get(userEndpoints.getGraduationDate + params);
    if (data.success) {
      dispatch({ type: Home.GET_GRADUATION_DATE_SUCCESS, payload: data });
    }
  } catch (error) {
    dispatch({
      type: Home.GET_GRADUATION_DATE_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const setUMEHeader = (params) => (dispatch) => {
  try {
    dispatch({ type: Home.UME_PROGRAM_FULL_DETAILS });

    if (params) dispatch({ type: Home.UME_PROGRAM_FULL_DETAILS, payload: params });

  } catch (error) {
    console.error(error, "errordisplying")
  }

};