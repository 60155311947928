const loginData = (userDetails) => {
  const userTypeArray = userDetails.data.UserTypeForPrograms;

  let userType = [];

  //It checks length > 1 and if the first element in userTypeArray  has a UserTypeID equal to 1
  if (userTypeArray.length >= 1 && userTypeArray[0]?.UserTypeID == 1) {
    //it checks if there are any elements in userTypeArray that have a UserTypeID equal to 3 or 4 
    if (userTypeArray.some(item => item.UserTypeID == 3 || item.UserTypeID == 4)) {
      //If there are elements with UserTypeID equal to 3 or 4, it finds the first element in userTypeArray with a UserTypeID of 3 or 4 using find and pushes the UserTypeID of that element into the userType array.
      const userTypeObject = userTypeArray.find(item => item.UserTypeID == 3 || item.UserTypeID == 4);
      userType.push(userTypeObject.UserTypeID);
    } else {
      userType.push(userTypeArray[0]?.UserTypeID);
    }
  } else {
    userType = userTypeArray
      .filter(item => item.UserTypeID !== 1 && item.UserTypeID !== 5)
      .map(item => item.UserTypeID);
  }   // Initially not storing userType 1 & 5  

  localStorage.setItem("token", userDetails.token);
  localStorage.setItem("userType", userType[0]);
  localStorage.setItem("userId", userDetails.data.ID);
  localStorage.setItem("hospitalId", userDetails.data.HospitalID);
  localStorage.setItem("userLogin", userDetails.data.FirstName + " " + userDetails.data.LastName);
  localStorage.setItem("userPicture", userDetails.data.Picture);
};

export default loginData;

export const auth_user = () => {
  const user = {
    token: localStorage.getItem("token"),
    userType: localStorage.getItem("userType"),
    userId: localStorage.getItem("userId"),
    hospitalId: localStorage.getItem("hospitalId"),
    userLogin: localStorage.getItem("userLogin"),
    userPicture: localStorage.getItem("userPicture"),
  };
  return user;
};

export const isLogin = () => {
  if (localStorage.getItem("token")) return true;
  return false;
};

export const logout = () => localStorage.clear();
