// const apiUrl = "http://growthscapedev.oneteamus.com:3001/";
// const fileUrl = "http://growthscapedev.oneteamus.com:3001/user/getfile/";

// const apiUrl = "https://api.residentgrowthchart.com/";
// const fileUrl = " https://api.residentgrowthchart.com/user/getfile/";

const apiUrl = "https://stageapi.residentgrowthchart.com/";
const fileUrl = " https://stageapi.residentgrowthchart.com/user/getfile/";


// const apiUrl = "https://growthscapedev.oneteamus.com:3001";
// const fileUrl = "https://growthscapedev.oneteamus.com:3001/getfile/";

//const apiUrl = "https://residentgrowthchart.com:3002/";
//const fileUrl = "https://residentgrowthchart.com:3002/user/getfile/";

// const apiUrl = "http://34.130.211.208:3001/"
// const fileUrl = "http://34.130.211.208:3001/user/getfile/";

export const API_ROUTE = `${apiUrl}`;
export const FILE_ROUTE = `${fileUrl}`;
