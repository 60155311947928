import { Directory } from "../../actionTypes";



const DirectoryReducer = (state = {}, action) => {
  switch (action.type) {
    case Directory.PROGRAM_LIST_REQUEST:
      return { isLoading: true };
    case Directory.MEMBER_LIST:
      return { isLoading: true };
    case Directory.USER_TYPES:
      return { isLoading: true };
    case Directory.PROGRAM_LIST_SUCCESS:
      return { isLoading: false, data: action.payload };
    case Directory.PROGRAM_LIST_FAILURE:
      return { isLoading: false, error: action.payload };

    default:
      return state;
  }
};
export default DirectoryReducer;
